import React from "react"
import PropTypes from "prop-types"

const LabelRenderer = ({ labelText }) => {
  const split = labelText.split("CS")
  const number = split[1]
  return (
    <h2 className="mb1" style={{ fontSize: "4em" }}>
      <span style={{ fontWeight: "300" }}>CS</span>
      <span style={{ fontWeight: "600" }}>{number}</span>
    </h2>
  )
}

LabelRenderer.propTypes = {
  labelText: PropTypes.string.isRequired
}

export default LabelRenderer
