import React from "react"
import Layout from "@app-components/layout/"
import Background from "@app-components/utils/background"
import Plans from "@page-components/products/dcp-manager/pricing/"
import Navigation from "@app-components/ui/header/components/navigation"
import HEADERS from "@data/headers/"

const Pricing = () => (
  <Layout getStartedButtons={["demo", "get-started"]} page="products/dcp-manager/pricing">
    <Background className="bg-cover bg-no-repeat bg-center" tagName="section" type="red-single">
      <Navigation navigation={HEADERS.dcp_manager} extraHeight />
      <div
        style={{
          paddingTop: "12rem",
          paddingBottom: "14rem",
          marginBottom: "-13rem"
        }}
        className="white center bg-cover px2 md-px3 md-px0 pb4">
        <h1>Build a system to fit your needs</h1>
        <p className="large-p-tag max-width-2 mx-auto mt1">
          Select a model and storage configuration to fit all the films at your festival.
        </p>
      </div>
    </Background>
    <Plans />
  </Layout>
)

export default Pricing
