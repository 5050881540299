import React from "react"
import PropTypes from "prop-types"

const Image = ({ component, pos }) => {
  const ImageComponent = component
  return (
    <div
      style={{
        position: "absolute",
        bottom: pos.bottom,
        right: pos.right,
        width: "380px",
        margin: "0 auto 30px auto",
        opacity: "0.15"
      }}>
      <ImageComponent />
    </div>
  )
}

Image.propTypes = {
  component: PropTypes.func.isRequired,
  pos: PropTypes.object.isRequired
}

export default Image
