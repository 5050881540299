const addons = [
  {
    name: "CS6 Pelican Transport Case",
    price: "449",
    currency_symbol: "$"
  },
  {
    name: "CS6 Storage Upgrade to 56TB Usable",
    price: "2,499",
    currency_symbol: "$"
  },
  {
    name: "2TB High-speed (7200RPM) CRU Drive Kit",
    price: "235",
    currency_symbol: "$"
  },
  {
    name: "4TB High-speed (7200RPM) CRU Drive Kit",
    price: "285",
    currency_symbol: "$"
  },
  {
    name: "Zebra Thermal DCP Label Printer",
    price: "450",
    currency_symbol: "$"
  }
]

export default addons
