import React, { useState } from "react"
import { css } from "@emotion/core"
import plans from "@data/pricing/dcp_manager"
import addons from "@data/pricing/dcp_manager_addons"
import FestivalsSignupModal from "@page-components/festivals/signup-modal"
import Image from "./components/image"
import LabelRenderer from "./components/label"

const Plans = () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <section className="px2 md-px3 mt3">
      <div className="flex flex-wrap items-stretch max-width-5 mx-auto mb3">
        {plans.map(plan => (
          <div
            key={plan.label}
            className="col-12 sm-col-10 md-col-8 lg-col-6 mx-auto px1 lg-mb0 mb4 center max-width-2">
            <div
              css={css`
                position: relative;
                height: 100%;
                border-radius: 8px;
                box-shadow: 0 9px 25px 0 rgba(56, 62, 67, 0.12);
                overflow: hidden;
              `}
              className="bg-white p3">
              <div
                css={css`
                  z-index: 1;
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                `}>
                <Image component={plan.image} pos={plan.imagePos} />
              </div>

              <div
                css={css`
                  z-index: 2;
                  position: relative;
                `}>
                <LabelRenderer labelText={plan.label} />
                <p className="mb3">{plan.description}</p>
                <div className="mb3">
                  <strong
                    className="block center"
                    css={css`
                      font-size: 38px;
                    `}>
                    ${plan.price}
                  </strong>
                </div>
                <button onClick={() => setShowModal(true)} tabIndex="-1" className="col-12 block">
                  Customize and Buy
                </button>
                <ul className="pl0">
                  {plan.features.map(item => (
                    <li className="list-style-none flex items-center py1" key={item}>
                      <span className="material-icons mr1">check</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-wrap items-stretch max-width-5 mx-auto mb3">
        {addons.map((addon, i) => (
          <div
            className="col-12 mx-auto px3 mb2"
            css={css`
              height: 85px;
            `}
            key={i}>
            <div
              css={css`
                height: 100%;
                border-radius: 8px;
                box-shadow: 0 9px 25px 0 rgba(56, 62, 67, 0.12);
              `}
              className="flex bg-white p3 items-center">
              <div className="flex-auto flex items-center">
                <h4 className="mr1">{addon.name}</h4>
              </div>
              <div className="relative inline-block pl2">
                <div
                  css={css`
                    font-size: 18px;
                  `}
                  className="absolute top-0 left-0">
                  {addon.currency_symbol}
                </div>
                <span
                  css={css`
                    font-size: 36px;
                    line-height: 36px;
                  `}>
                  {addon.price}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <p className="center light py2">
        All fees, including monthly subscription fees, are subject to applicable local taxes.
      </p>
      {showModal && <FestivalsSignupModal onClose={() => setShowModal(false)} />}
    </section>
  )
}

export default Plans
