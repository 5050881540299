import CS3Image from "@page-components/products/dcp-manager/media/cs3"
import CS6Image from "@page-components/products/dcp-manager/media/cs6"

const common = [
  "2x rear accessible USB3 port",
  "Free software updates forever",
  "FTP Offload and Ingest",
  "Download bandwidth throttling"
]

const plans = [
  {
    label: "CS3",
    image: CS3Image,
    imagePos: {
      bottom: "-150px",
      right: "-130px"
    },
    description: "Ideal for festivals that screen 20-100 films",
    price: "3,499",
    features: ["12TB usable storage", "3x CRU slots", ...common]
  },
  {
    label: "CS6",
    image: CS6Image,
    imagePos: {
      bottom: "-260px",
      right: "-130px"
    },
    description: "Ideal for festivals that screen 100-350 films",
    price: "4,999",
    features: ["16TB usable storage", "6x CRU slots", "1x front accessible USB3 port", ...common]
  }
]

export default plans
